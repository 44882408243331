import React, {useEffect, useState,useRef} from 'react';
import {Layout, message, notification} from 'antd';
import './styleBigScreen.css';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {storage} from '../../utils/storage';
// import CustomRouters from '../routers';
import {Outlet, useNavigate, useLocation, Link} from 'react-router-dom';
import {sendNoticeInfo, side, updateMyInfo, changeImportType} from '../../store/actions';
import {getUserInfo} from '../../api';
import WebSocketClient from '../../api/webSocket';
import {WEBSOCKETURL} from '../../api/Urls';
import BikeScreen from '../../asset/screen/bikeScreen.png';
import BikeManage from '../../asset/screen/bikeManage.png';
import ElectricBikeScreen from '../../asset/screen/electricBikeScreen.png';
import ElectricBikeManage from '../../asset/screen/electricBikeManage.png';
import BikeDateStatistics from '../../asset/screen/bikeDateStatistics.png';
import MaintainRecord from '../../asset/screen/maintainRecord.png';
import WorkOrder from '../../asset/screen/workOrder.png';
import BikeGuaranteeArea from '../../asset/screen/bikeGuaranteeArea.png';
import TitleBg from '../../asset/screen/titleBg.png';

const BigScreen = ({setNoticeInfo}) => {
  const ng = useNavigate();
  const [l] = useState(useLocation());
  const [style, setStyle] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const websocketRef = useRef(null);
  const openNotificationWithIcon = (type, msg) => {
    const key = `open${Date.now()}`;
    const btn =<Button type="primary" size="small" onClick={() => notification.close(key)}>确定</Button>;
    api[type]({
      message: type==='success'?'单车批量导入成功':'单车批量导入失败',
      description: msg,
      key,
      btn,
      duration: 0,
    });
  };
  const [pageList, setPageList] = useState([
    {
      name: '单车大屏',
      icon: BikeScreen,
      url: '/bigScreen/cockpitScreen',
      state: true,
      style: false, // 大屏全屏一屏展示，其他可以滚动
    },
    {
      name: '单车人员大屏',
      icon: BikeManage,
      url: '/bigScreen/screen/bikeManage',
      state: false,
      style: false,
    },
    {
      name: '电单车大屏',
      icon: ElectricBikeScreen,
      url: null,
      state: false,
      style: false,
    },
    {
      name: '电单车人员大屏',
      icon: ElectricBikeManage,
      url: '/bigScreen/screen/electricBikeManage',
      state: false,
      style: false,
    },
    {
      name: '单车数据统计',
      icon: BikeDateStatistics,
      url: '/bigScreen/dataStatistics/bikeDateStatistics',
      state: false,
      style: true,
    },
    {
      name: '定点运维记录',
      icon: MaintainRecord,
      url: '/bigScreen/workManagement/maintainRecord',
      state: false,
      style: true,
    },
    {
      name: '秩序及调运工单',
      icon: WorkOrder,
      url: '/bigScreen/workOrderManagement/workOrder',
      state: false,
      style: true,
    },
    {
      name: '单车保障区域',
      icon: BikeGuaranteeArea,
      url: '/bigScreen/guaranteeArea/bikeGuaranteeArea',
      state: false,
      style: true,
    },
  ]);

  // const getPopupContainer = (node) => {
  //   const el = document.getElementById('new-body');
  //   // console.log(el, 1111);
  //   if (el) {
  //     return el;
  //   }
  //   return document.body;
  // };

  const changePage = (data)=>{
    if (!data.state && data.url) {
      setPageList([...pageList.map((item)=>{
        if (item.name === data.name) {
          item.state = true;
        } else {
          item.state = false;
        }
        return item;
      })]);
      setStyle(data.style);
      // window.location.href = data.url
    }
    // else if (!data.url){
    //   message.info('')
    // }
  };

  useEffect(() => {
    // if (userInfo) {
    getUserInfo(2).then((res) => {
      if (Reflect.ownKeys(res.data).length > 0) {
        const data={
          userName: res.data.userName,
          bikeEnterprise: res.data.bikeEnterprise,
          areaName: res.data.areaName,
        };
        localStorage.setItem('bikeEnterprise', JSON.stringify(data));
        localStorage.setItem('myInfo', JSON.stringify(res.data));
      }
    });
    // }
  }, []);

  useEffect(()=>{
    const login=storage.getData('userInfo') ?? false;
    if (!login) {
      ng('/login');
    } else {
      if (l.pathname==='/') {
        ng('/home');
      }
    }
    setPageList([...pageList.map((item)=>{
      console.log(item.url === location.pathname)
      if (location.pathname.indexOf(item.url) !== -1) {
        item.state = true;
        setStyle(item.style);
      } else {
        item.state = false;
      }
      return item;
    })]);
  }, [l]);
  // const url = 'ws://47.108.232.235:9000/websocket';
  // const url = 'wss://tips.smartecycle.cn:9110/websocket';
  function initWebSocket() {
    const token = storage.getData('userInfo')?.token?.split('Bearer ')[1] || '';
    websocketRef.current = new WebSocketClient(`${WEBSOCKETURL}?token=${token}`);
    websocketRef.current.onMessage((res) => {
      // console.log(res);
      // console.log(JSON.parse(res.data), new Date());
      const data=JSON.parse(res.data);
      if (data.type === 'message.web.count') {
        if (window.location.pathname === '/screen/bikeManage') {
          setNoticeInfo(JSON.parse(res.data));
        } else {
          setNoticeInfo({});
        }
      } else if (data.type === 'message.web.bike-upload') {
        if (data.message.code === 200) {
          openNotificationWithIcon('success', data.message.msg);
          setImportType(importTypeCount + 1);
        } else {
          openNotificationWithIcon('error', data.message.msg);
        }
      }
    });
    websocketRef.current.connect(); // connect to the WebSocket server
    // openNotificationWithIcon('error', null, 1);
  }
  useEffect(() => {
    initWebSocket();
    return () => {
      websocketRef.current.disconnect(); // disconnect from the WebSocket server
    };
  }, []);
  return (
    <Layout style={{width: '3840px', height: '1080px', overflow: 'hidden', boxSizing: 'border-box'}}>
      {contextHolder}
      <div className="header-title-b layout-header">
        <Link className="header-btn" to={'/home'} target="_blank">工作台</Link>
        成都市共享单车智慧监管系统
      </div>
      <div className={'big-screen-layout'}>
        {['/bigScreen/cockpitScreen'].includes(location.pathname)?<div style={{flex: 1, height: 1016}}><Outlet/></div> :(
          <div className="custom-layout-left">
            <div id="new-body" className={`page-style ${style ||
               location.pathname.indexOf('keySecurityAreaMessage') !== -1 || 
               location.pathname.indexOf('bikeManage/staffDetails') !== -1 ? 'page-no-screen':''}`}> 
              <Outlet />
            </div>
          </div>
        )}

        <div className="custom-layout-right">
          <div className="box-right">
            <div className="page-header">
              <span className="page-header-text">功能模块</span>
              <img src={TitleBg} className="page-header-img"/>
            </div>
            <div className="page-all">
              {pageList.map((item, index)=>{
                return (<>
                  <Link
                    key={index}
                    className={`page-box ${item.state? 'page-box-click':''}`}
                    onClick={()=>changePage(item)}
                    to={item.url? item.url:'#'}
                  >
                    <img src={item.icon} className="page-box-icon"/>
                    <span>{item.name}</span>
                  </Link>
                </>);
              })}
            </div>
          </div>

        </div>
      </div>
    </Layout>
  );
};

BigScreen.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  setCollapsedMenus: PropTypes.func.isRequired,
  myInfoCount: PropTypes.number.isRequired,
  setMyInfoCount: PropTypes.func.isRequired,
  setNoticeInfo: PropTypes.func.isRequired,
  importTypeCount: PropTypes.number.isRequired,
  setImportType: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => {
  return {
    collapsed: state.collapsedMenus.collapsed,
    myInfoCount: state.myInfo.myInfoCount,
    importTypeCount: state.importType.count,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCollapsedMenus: (f) => dispatch(side(f)),
    setMyInfoCount: (n) => dispatch(updateMyInfo(n)),
    setNoticeInfo: (obj) => dispatch(sendNoticeInfo(obj)),
    setImportType: (n)=>dispatch(changeImportType(n)),
  };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BigScreen);


