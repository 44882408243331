import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import DetailsHeader from '../../../components/detailsHeader';
import {Anchor, Card, Col, Divider, Row, Skeleton, Button, Switch, Tooltip, message} from 'antd';
import CustomTable from '../../../components/customTable';
import PersonNumber from './PersonNumber';
import {getGuarantAreaDetails, setHideArea} from '../../../api';
import {GETGUARANTEESTATISTICSLIST} from '../../../api/Urls';
import {guaranteeAreaStatus, bikeCompany} from '../../../components/statusCollection';
import {InfoCircleOutlined, InfoCircleFilled} from '@ant-design/icons';
import PropTypes from 'prop-types';
import {timeFormat} from '../../../components/baseTime';
import './style.css';

const AreaDetails = ({queryType}) => {
  const d = useParams();
  const urlSrceen = location.pathname.indexOf('/bigScreen') === -1? '':'/bigScreen';
  const [loading] = useState(false);
  const [numberModal, setNumberModal] = useState(false);
  const [baseInfo, setBaseInfo] = useState({});
  const [personNum, setPersonNum] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [isCheck, setIsCheck] = useState(false);
  const enterprise = JSON.parse(localStorage.getItem('bikeEnterprise'))?.bikeEnterprise;
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      onExport: true,
      exportRender: (render) => render.index,
    },
    {
      title: '美团到岗数',
      dataIndex: 'mt',
      key: 'mt',
      company: 1,
      // eslint-disable-next-line
      render: (mt, item) => (
        <>
          <span>人员：{personOrCar(item.statistic, 1)?.person || 0}</span> | &nbsp;
          <span>车辆：{personOrCar(item.statistic, 1)?.car || 0}</span>
        </>
      ),
      onExport: true,
      // eslint-disable-next-line max-len
      exportRender: (render) =>
        `人员：${personOrCar(render.statistic, 1)?.person || 0} |  车辆:${personOrCar(render.statistic, 1)?.car || 0}`,
    },
    {
      title: '青桔到岗数',
      dataIndex: 'qj',
      key: 'qj',
      company: 2,
      // eslint-disable-next-line
      render: (_, item) => (
        <>
          <span>人员：{personOrCar(item.statistic, 2)?.person || 0}</span> | &nbsp;
          <span>车辆：{personOrCar(item.statistic, 2)?.car || 0}</span>
        </>
      ),
      onExport: true,
      // eslint-disable-next-line max-len
      exportRender: (render) =>
        `人员：${personOrCar(render.statistic, 2)?.person || 0} |  车辆:${personOrCar(render.statistic, 2)?.car || 0}`,
    },
    {
      title: '哈啰到岗数',
      dataIndex: 'hl',
      key: 'hl',
      company: 3,
      // eslint-disable-next-line
      render: (_, item) => (
        <>
          <span>人员：{personOrCar(item.statistic, 3)?.person || 0}</span> | &nbsp;
          <span>车辆：{personOrCar(item.statistic, 3)?.car || 0}</span>
        </>
      ),
      onExport: true,
      // eslint-disable-next-line max-len
      exportRender: (render) =>
        `人员：${personOrCar(render.statistic, 3)?.person || 0} |  车辆:${personOrCar(render.statistic, 3)?.car || 0}`,
    },
    {
      title: '统计时间',
      dataIndex: 'createTime',
      key: 'createTime',
      onExport: true,
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      // eslint-disable-next-line
      render: (_, item) => (
        <Button
          type="link"
          disabled={disabled(item)}
          onClick={() => {
            setNumberModal(true);
            item.statistic = statisticData(item);
            setStatistics(item);
          }}
        >
          详情
        </Button>
      ),
    },
  ];
  const personOrCar = (statistic, val) => {
    return statistic.find(({company}) => company === val);
  };
  // 操作详情disabled设置
  const disabled = (value) => {
    const data = statisticData(value);
    return data.every((item) => item?.person === 0 && item?.car === 0);
  };
  // 数据处理
  const statisticData = (value) => {
    const temp = [];
    for (const index of enterprise) {
      const res = value.statistic.find((i) => i.company === index);
      if (res) temp.push(res);
    }
    return temp;
  };
  // 提示说明
  const tooltip = () => {
    return (
      <div className="tool-tip-color">
        <div>
          <InfoCircleFilled className="tool-tip" />
          <span>提示说明</span>
        </div>
        <Divider className="margin-t-s margin-b-s" />
        {/* <div className="tip-info">开启隐藏功能，前往大屏模块的地图上无法查案进行中和未开始状态的保障区域区域。</div> */}
        <div className="tip-info">开启开关，【前往大屏-单车人员大屏】页面，地图隐藏进行中和未开始状态的保障区域，并暂停相关统计。</div>
      </div>
    );
  };
  // 保障区域详情获取
  const getAreaDetails =()=>{
    getGuarantAreaDetails(d.id, 2).then((res) => {
      if (res.data) {
        setBaseInfo(res.data);
        if (res.data.guaranteeStatus === 2) {
          setIsCheck(false);
        } else {
          setIsCheck(res.data.hideArea?true:false);
        }
        const arr = res.data.addSecurityAreaNum.map((item) => {
          item[`jobType${item.jobType}`] = item.personNum;
          delete item.jobType;
          delete item.personNum;
          return item;
        });
        const numArr = [];
        arr.forEach((element) => {
          const sIndex = numArr.findIndex((i) => i.bikeType === element.bikeType);
          if (sIndex === -1) {
            numArr.push(
              queryType === 1 ?
                {
                  bikeType: element.bikeType,
                  jobType1: element.jobType1,
                } :
                {
                  bikeType: element.bikeType,
                  jobType5: element.jobType5,
                },
            );
          } else {
            if (queryType === 1) {
              numArr[sIndex].jobType2 = element.jobType2;
            } else {
              numArr[sIndex].jobType6 = element.jobType6;
            }
          }
        });
        const data=[
          {bikeType: 1, jobType1: 0, jobType2: 0},
          {bikeType: 2, jobType1: 0, jobType2: 0},
          {bikeType: 3, jobType1: 0, jobType2: 0},
        ];
        data.forEach((value, index)=>{
          numArr.forEach((item, i)=>{
            if (item.bikeType===value.bikeType) {
              data[index]=numArr[i];
            }
          });
        });
        const temp = [];
        for (const index of enterprise) {
          const res = data.find((i) => i.bikeType === index);
          if (res) temp.push(res);
        }
        setPersonNum(temp);
      }
    });
  };
  // 区域设置
  const changeCheck=(checked)=>{
    setIsCheck(checked);
    const data={
      id: d.id,
      hideArea: checked?1:0,
    };
    setHideArea(data, 2).then((resp)=>{
      if (resp.code===200) {
        message.success('区域设置成功！');
        getAreaDetails();
      }
    }).catch((err)=>{
      message.error('区域设置失败！');
    });
  };
  useEffect(() => {
    console.log(urlSrceen,111)
    getAreaDetails();
  }, []);
  return (
    <div style={{position:'static'}}>
      <DetailsHeader
        pageName="单车保障区域详情"
        buttonGroup={() => (
          // guaranteeStatus 0未开始 1进行中 2已完成
          <Button type="primary" onClick={() => {}} hidden={baseInfo.guaranteeStatus === 2 || !baseInfo.editStatus}>
            <Link
              to={
                queryType === 1 ?
                  `${urlSrceen}/guaranteeArea/bikeGuaranteeArea/add/edit` :
                  `${urlSrceen}/guaranteeArea/electricBikeGuaranteeArea/add/edit`
              }
              state={{operationType: 'edit', baseInfo: {id: d.id, personNum, baseInfo}}}
            >
              编辑保障区域
            </Link>
          </Button>
        )}
      />
      <Card className="padding-r-s padding-l-s padding-t-s padding-b-s">
        <div>
          <h3 className="details-title" id="basic-information">
            基础信息
          </h3>
          {loading ? (
            <Skeleton active />
          ) : (
            <div className="details-info margin-t-l">
              <Row gutter={24} className="margin-b-l">
                <Col span={8}>
                  保障区域名称：<span className="text-gray">{baseInfo.guaranteeName||'暂无数据'}</span>
                </Col>
                <Col span={8}>
                  状态：<span className="text-gray">{guaranteeAreaStatus(baseInfo.guaranteeStatus)||'暂无数据'}</span>
                </Col>
                <Col span={8}>
                  创建人：<span className="text-gray">{baseInfo.createName||'暂无数据'}</span>
                </Col>
              </Row>
              <Row gutter={24} className="margin-b-l">
                <Col span={8}>
                  开始时间：<span className="text-gray">{baseInfo.startTime?timeFormat(baseInfo.startTime):'暂无数据'}</span>
                </Col>
                <Col span={8}>
                  结束时间：<span className="text-gray">{baseInfo.endTime?timeFormat(baseInfo.endTime):'暂无数据'}</span>
                </Col>
                <Col span={8}>
                  创建时间：<span className="text-gray">{baseInfo.createTime?timeFormat(baseInfo.createTime):'暂无数据'}</span>
                </Col>
              </Row>
            </div>
          )}
        </div>
        <Divider />
        <div>
          <h3 className="details-title" id="page-range">
            区域设置
          </h3>
          {loading ? (
            <Skeleton active />
          ) : (
            <div className="area-details" style={{display: 'flex', alignItems: 'center'}}>
              <div>
              隐藏区域并暂停统计
                <Tooltip placement="topLeft" title={tooltip()} color="white" className="margin-l-s">
                  <InfoCircleOutlined className="icon-color" style={{marginRight: '8px'}}/>
                </Tooltip>
                <Switch
                  onChange={(checked) => {
                    changeCheck(checked);
                  }}
                  checked={isCheck}
                  disabled={baseInfo.guaranteeStatus === 2 || !baseInfo.editStatus}
                />
              </div>
              {/* <div className="area-details-switch" >
                <Switch
                  onChange={(checked) => {
                    changeCheck(checked);
                  }}
                  checked={isCheck}
                  disabled={baseInfo.guaranteeStatus === 2 || !baseInfo.editStatus}
                />
              </div> */}
            </div>
          )}
        </div>
        <Divider />
        <div>
          <h3 className="details-title" id="job-information">
            应配人数
          </h3>
          {loading ? (
            <Skeleton active />
          ) : (
            <div className="details-info margin-t-l">
              <Row gutter={[16, 0]} className="margin-b-l">
                {personNum.map((item, index) => {
                  return (
                    <Col key={item.bikeType + index}>
                      <div className="number-item">
                        <div className="number-item-title">{bikeCompany(item.bikeType)}</div>
                        <div className="number-item-content">
                          {(queryType === 1 ? '单车' : '电单车') + '人员应配数：'}
                          <span className="text-gray">
                            {personNum.length > 0 && queryType === 1 ? item.jobType1 : item.jobType5}
                          </span>
                        </div>
                        <div>
                          {(queryType === 1 ? '单车' : '电单车') + '调运车辆应配数：'}
                          <span className="text-gray">
                            {personNum.length > 0 && queryType === 1 ? item.jobType2 : item.jobType6}
                          </span>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          )}
        </div>
        <Divider />
        {/* 到岗统计 */}
        <div style={{marginRight: '70px'}}>
          <div id="person-setting"></div>
          <div className="margin-t-l">
            <CustomTable
              InitApi={`${GETGUARANTEESTATISTICSLIST}${d.id}`}
              toIndex={true}
              columns={columns}
              resType="POST"
              firm={[1, 2]} // [0]代表数据第一个需要处理的下标，[1]代表数据倒数第几个结束的下标
              updateList={0}
              customTitle={'到岗统计'}
              tip="到岗统计导出中..."
              exportFileName={baseInfo.guaranteeStatus !== 0 ? '到岗统计记录' : null}
            />
          </div>
        </div>
      </Card>
      <PersonNumber numberModal={numberModal} setNumberModal={setNumberModal} statistics={statistics} />
      <Anchor 
        // className="anchor-screen"
        className={`${location.pathname.indexOf('/bigScreen') !== -1? 'anchor-screen':'anchor'}`}
      >
        <Anchor.Link href="#basic-information" title="基础信息" />
        <Anchor.Link href="#page-range" title="区域设置" />
        <Anchor.Link href="#job-information" title="应配人数" />
        <Anchor.Link href="#person-setting" title="到岗统计" />
      </Anchor>
    </div>
  );
};

AreaDetails.propTypes = {
  queryType: PropTypes.number.isRequired,
};

export default AreaDetails;
