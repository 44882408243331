import React, {useEffect, useState} from 'react';
import {ConfigProvider, Radio} from 'antd';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import enUS from 'antd/lib/locale/en_US';
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';

import 'moment/locale/zh-cn';
import './locales/i18n';
import './App.less';

import Login from './pages/login';

import CustomLayout from './components/layout/index';
import BigScreen from './components/layout/bigScreen';

import ErrorPage from './pages/errorPage';
import Roles from './pages/accessControl/roles';
import Users from './pages/accessControl/users';
import Home from './pages/home';
import AttendanceRecord from './pages/workManagement/attendanceRecord';
import DispatchRecord from './pages/workManagement/dispatchRecord';
import MaintainRecord from './pages/workManagement/maintainRecord';
import UnusualRecord from './pages/workManagement/unusualRecord';
import AttendanceManagement from './pages/attendanceManagement';
import BicycleManagement from './pages/bicycleManagement';
import StaffManagement from './pages/personnelManagement';
import DataStatistics from './pages/dataStatistics/bikeDateStatistics';
import BikePersonDataStatistics from './pages/dataStatistics/bikePersonDataStatistics';
import RegionalSecurity from './pages/dataStatistics/regionalSecurity';

import AttendanceDetails from './pages/attendanceManagement/components/AttendanceDetails';
import ConsoleLog from './pages/logManagement/consoleLog';
import LoginLog from './pages/logManagement/loginLog';
import AttendanceRecordDetails from './pages/workManagement/attendanceRecord/components/AttendanceDetails';
import AttendanceRecordPosition from './pages/workManagement/attendanceRecord/components/AttendanceRecordPosition';
import DispatchDetails from './pages/workManagement/dispatchRecord/components/DispatchDetails';
import StaffDetails from './pages/personnelManagement/components/StaffDetails';
import MaintainDetails from './pages/workManagement/maintainRecord/components/MaintainDetails';
import RoleDetails from './pages/accessControl/roles/components/RoleDetails';
import UserDetails from './pages/accessControl/users/components/UserDetails';
import SettingAttendanceGroup from './pages/attendanceManagement/components/SettingAttendanceGroup';
import StaffPosition from './pages/personnelManagement/components/StaffPosition';
import UnusualDetails from './pages/workManagement/unusualRecord/components/UnusualDetails';
import BikeManager from './pages/screen/bikeManage';
import ScreenStaffDetails from './pages/screen/bikeManage/components/StaffDetails';
// import PolyEdit from './components/aMap/PolyEdit';
import GuaranteeArea from './pages/guaranteeArea';
import KeySecurityArea from './pages/guaranteeArea/KeySecurityArea';
import AreaDetails from './pages/guaranteeArea/components/AreaDetails';
import KeyAreaDetails from './pages/guaranteeArea/components/KeyAreaDetails';
import OperateArea from './pages/guaranteeArea/components/OperateArea';
import KeyOperateArea from './pages/guaranteeArea/components/KeyOperateArea';
import BikeScreen from './pages/screen/bikeScreen';
import BikeSummaryReport from './pages/summaryReport/bikeSummaryReport';
import EquipmentList from './pages/deviceManagement/equipmentList';
import DeviceManage from './pages/deviceManagement/bikeEquipmentMonitor';
import MonitorDetails from './pages/deviceManagement/bikeEquipmentMonitor/monitorDetails';
import SystemSetting from './pages/systemSetting';
import EquipmentDetails from './pages/deviceManagement/equipmentList/components/EquipmentDetails';
import WorkOrder from './pages/workOrderManagement';
import WorkOrderDetails from './pages/workOrderManagement/components/workOrderDetails';
import NoPermission from './pages/errorPage/NoPermission';
import UserAccessRecord from './pages/mobileDataMonitoring/userAccessRecord';
import KeySecurityAreaMessage from './pages/messageCenter/keySecurityAreaMessage';
import ImportRecords from './pages/bicycleManagement/components/ImportRecords';
import SSO from './pages/SSO';
import PilotScreen from './pages/screen/pilotScreen';

const App = () => {
  const lang = 'zh';
  const [locale, setLocale] = useState(zhCN);
  // eslint-disable-next-line object-curly-spacing
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(lang);
    document.title = lang === 'en' ? 'System' : '成都市共享单车智慧监管系统';
  }, []);

  const getPopupContainer = (node) => {
    const el = document.getElementById('new-body');
    console.log(el, 1111);
    if (el) {
      return el;
    }
    return document.body;
  };

  return (
    <BrowserRouter>
      <div className="change-locale">
        <Radio.Group
          value={locale}
          onChange={(e) => {
            setLocale(e.target.value);
            if (e.target.value.locale !== 'en') {
              i18n.changeLanguage('zh');
              document.title = '成都市共享单车智慧监管系统';
            } else {
              i18n.changeLanguage('en');
              document.title = 'System';
            }
            moment.locale(e.target.value.locale);
          }}
        >
          <Radio.Button key="en" value={enUS}>
            EN
          </Radio.Button>
          <Radio.Button key="cn" value={zhCN}>
            中
          </Radio.Button>
        </Radio.Group>
      </div>
      <ConfigProvider getPopupContainer={getPopupContainer} locale={locale}>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/sso/:token" element={<SSO />} />
          {/* <Route path="/react" element={<div id="subapp-viewport" />} /> */}
          {/* <Route exact path="/test" element={<PolyEdit />} /> */}
          <Route path="/" element={<CustomLayout />}>
            <Route path="home" element={<Home />} />
            <Route path="react" element={<div id="subapp-viewport" />} />
            <Route path="react/*" element={<div id="subapp-viewport" />} />
            <Route path="workManagement">
              <Route path="attendanceRecord" element={<AttendanceRecord />} />
              <Route path="attendanceRecord/details/:id/:operationId" element={<AttendanceRecordDetails />} />
              <Route path="attendanceRecord/position/:id/:operationId" element={<AttendanceRecordPosition />} />
              <Route path="dispatchRecord" element={<DispatchRecord />} />
              <Route path="dispatchRecord/position/:id/:type/:operationId/:key" element={<DispatchDetails />} />
              <Route path="dispatchRecord/position/:id/:type/:operationId/:lockId/:key" element={<DispatchDetails />} />
              <Route path="maintainRecord" element={<MaintainRecord />} />
              <Route path="maintainRecord/details/:id/:operationId" element={<MaintainDetails />} />
              <Route path="unusualRecord" element={<UnusualRecord />} />
              <Route path="unusualRecord/details/:id/:operationId/:key" element={<UnusualDetails />} />
              <Route
                path="electricBikeManage/staffDetails/:operationId"
                element={<ScreenStaffDetails queryType={2} />}
              />

              <Route path="bikeScreen" element={<BikeScreen queryType={1} />} />
              <Route path="electricBikeScreen" element={<BikeScreen queryType={2} />} />
            </Route>
            <Route path="screen">
              <Route path="bikeManage" element={<BikeManager queryType={1} />} />
              <Route path="bikeManage/staffDetails/:operationId" element={<ScreenStaffDetails queryType={1} />} />
              <Route path="electricBikeManage" element={<BikeManager queryType={2} />} />
              <Route
                path="electricBikeManage/staffDetails/:operationId"
                element={<ScreenStaffDetails queryType={2} />}
              />
              <Route path="bikeScreen" element={<BikeScreen bikeType={1} />} />
              <Route path="electricBikeScreen" element={<BikeScreen bikeType={2} />} />
            </Route>
            <Route path="dataStatistics">
              <Route path="bikeDateStatistics" element={<DataStatistics bikeType={1} />} />
              <Route path="bikePersonDataStatistics" element={<BikePersonDataStatistics />} />
              <Route path="keySecurityAreaStatistics" element={<RegionalSecurity />} />
            </Route>
            <Route path="guaranteeArea">
              <Route path="bikeGuaranteeArea" element={<GuaranteeArea queryType={1} />} />
              <Route path="electricBikeGuaranteeArea" element={<GuaranteeArea queryType={2} />} />
              <Route path="keySecurityArea" element={<KeySecurityArea />} />
            </Route>
            <Route path="guaranteeArea/bikeGuaranteeArea/details/:id" element={<AreaDetails queryType={1} />} />
            <Route path="guaranteeArea/electricBikeGuaranteeArea/details/:id" element={<AreaDetails queryType={2} />} />
            <Route path="guaranteeArea/keySecurityArea/details/:id" element={<KeyAreaDetails queryType={1} />} />
            <Route path="guaranteeArea/bikeGuaranteeArea/add/edit" element={<OperateArea queryType={1} />} />
            <Route path="guaranteeArea/electricBikeGuaranteeArea/add/edit" element={<OperateArea queryType={2} />} />
            <Route path="guaranteeArea/keySecurityArea/add/edit" element={<KeyOperateArea queryType={1} />} />

            <Route path="attendanceManagement" element={<AttendanceManagement />} />
            <Route path="attendanceManagement/details/:id" element={<AttendanceDetails />} />
            <Route path="attendanceManagement/add" element={<SettingAttendanceGroup />} />
            <Route path="/deviceManagement">
              <Route path="bikeEquipmentMonitor" element={<DeviceManage />} />
              <Route path="bikeEquipmentMonitor/details/:id/:equipmentBatchNo/:count" element={<MonitorDetails />} />

              <Route path="equipmentList" element={<EquipmentList />} />
              <Route path="equipmentList/details/:id/:type" element={<EquipmentDetails />} />
            </Route>
            <Route path="bicycleManagement">
              <Route path="bikeManagement" element={<BicycleManagement queryType={1} />} />
              <Route path="bikeManagement/importRecords" element={<ImportRecords />} />
              <Route path="electricBikeManageMent" element={<BicycleManagement queryType={2} />} />
            </Route>

            <Route path="/personnelManagement">
              <Route path="staffManagement" element={<StaffManagement menuType={'staff'} />} />
              <Route path="urbanManagement" element={<StaffManagement menuType={'city'} />} />
              <Route path="staffManagement/details/:id" element={<StaffDetails menuType={'staff'} />} />
              <Route path="urbanManagement/details/:id" element={<StaffDetails menuType={'city'} />} />
              <Route path="staffManagement/position/:id" element={<StaffPosition />} />
            </Route>


            <Route path="/accessControl">
              <Route path="users" element={<Users />} />
              <Route path="users/details/:userId" element={<UserDetails />} />
              <Route path="roles" element={<Roles />} />
              <Route path="roles/details/:roleId" element={<RoleDetails />} />
            </Route>
            <Route path="workOrderManagement">
              <Route path="workOrder" element={<WorkOrder />} />
              <Route path="workOrder/details/:id" element={<WorkOrderDetails />} />
            </Route>
            <Route path="/mobileDataMonitoring">
              <Route path="userAccessRecord" element={<UserAccessRecord />} />
            </Route>
            <Route path="/messageCenter">
              <Route path="keySecurityAreaMessage" element={<KeySecurityAreaMessage />} />
            </Route>
            <Route path="/logManagement">
              <Route path="consoleLog" element={<ConsoleLog />} />
              <Route path="loginLog" element={<LoginLog />} />
            </Route>

            <Route path="summaryReport">
              <Route path="bikeSummaryReport" element={<BikeSummaryReport />} />
            </Route>
            <Route path="systemSetting" element={<SystemSetting />} />
            <Route path="noPermission" element={<NoPermission />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
          <Route path="/bigScreen" element={<BigScreen />}>
            <Route path="cockpitScreen" element={<PilotScreen bikeType={1}/>}></Route>
            <Route path="home" element={<Home />} />
            <Route path="react" element={<div id="subapp-viewport" />} />
            <Route path="react/*" element={<div id="subapp-viewport" />} />
            <Route path="workManagement">
              <Route path="attendanceRecord" element={<AttendanceRecord />} />
              <Route path="attendanceRecord/details/:id/:operationId" element={<AttendanceRecordDetails />} />
              <Route path="attendanceRecord/position/:id/:operationId" element={<AttendanceRecordPosition />} />
              <Route path="dispatchRecord" element={<DispatchRecord />} />
              <Route path="dispatchRecord/position/:id/:type/:operationId/:key" element={<DispatchDetails />} />
              <Route path="dispatchRecord/position/:id/:type/:operationId/:lockId/:key" element={<DispatchDetails />} />
              <Route path="maintainRecord" element={<MaintainRecord />} />
              <Route path="maintainRecord/details/:id/:operationId" element={<MaintainDetails />} />
              <Route path="unusualRecord" element={<UnusualRecord />} />
              <Route path="unusualRecord/details/:id/:operationId/:key" element={<UnusualDetails />} />
              <Route
                path="electricBikeManage/staffDetails/:operationId"
                element={<ScreenStaffDetails queryType={2} />}
              />

              <Route path="bikeScreen" element={<BikeScreen queryType={1} />} />
              <Route path="electricBikeScreen" element={<BikeScreen queryType={2} />} />
            </Route>
            <Route path="screen">
              <Route path="bikeManage" element={<BikeManager queryType={1} />} />
              <Route path="bikeManage/staffDetails/:operationId" element={<ScreenStaffDetails queryType={1} />} />
              <Route path="electricBikeManage" element={<BikeManager queryType={2} />} />
              <Route
                path="electricBikeManage/staffDetails/:operationId"
                element={<ScreenStaffDetails queryType={2} />}
              />
              <Route path="bikeScreen" element={<BikeScreen bikeType={1} />} />
              <Route path="electricBikeScreen" element={<BikeScreen bikeType={2} />} />
            </Route>
            <Route path="dataStatistics">
              <Route path="bikeDateStatistics" element={<DataStatistics bikeType={1} />} />
              <Route path="bikePersonDataStatistics" element={<BikePersonDataStatistics />} />
              <Route path="keySecurityAreaStatistics" element={<RegionalSecurity />} />
            </Route>
            <Route path="guaranteeArea">
              <Route path="bikeGuaranteeArea" element={<GuaranteeArea queryType={1} />} />
              <Route path="electricBikeGuaranteeArea" element={<GuaranteeArea queryType={2} />} />
              <Route path="keySecurityArea" element={<KeySecurityArea />} />
            </Route>
            <Route path="guaranteeArea/bikeGuaranteeArea/details/:id" element={<AreaDetails queryType={1} />} />
            <Route path="guaranteeArea/electricBikeGuaranteeArea/details/:id" element={<AreaDetails queryType={2} />} />
            <Route path="guaranteeArea/keySecurityArea/details/:id" element={<KeyAreaDetails queryType={1} />} />
            <Route path="guaranteeArea/bikeGuaranteeArea/add/edit" element={<OperateArea queryType={1} />} />
            <Route path="guaranteeArea/electricBikeGuaranteeArea/add/edit" element={<OperateArea queryType={2} />} />
            <Route path="guaranteeArea/keySecurityArea/add/edit" element={<KeyOperateArea queryType={1} />} />

            <Route path="attendanceManagement" element={<AttendanceManagement />} />
            <Route path="attendanceManagement/details/:id" element={<AttendanceDetails />} />
            <Route path="attendanceManagement/add" element={<SettingAttendanceGroup />} />
            <Route path="deviceManagement">
              <Route path="bikeEquipmentMonitor" element={<DeviceManage />} />
              <Route path="bikeEquipmentMonitor/details/:id/:equipmentBatchNo/:count" element={<MonitorDetails />} />

              <Route path="equipmentList" element={<EquipmentList />} />
              <Route path="equipmentList/details/:id/:type" element={<EquipmentDetails />} />
            </Route>
            <Route path="bicycleManagement">
              <Route path="bikeManagement" element={<BicycleManagement queryType={1} />} />
              <Route path="bikeManagement/importRecords" element={<ImportRecords />} />
              <Route path="electricBikeManageMent" element={<BicycleManagement queryType={2} />} />
            </Route>

            <Route path="personnelManagement">
              <Route path="staffManagement" element={<StaffManagement menuType={'staff'} />} />
              <Route path="urbanManagement" element={<StaffManagement menuType={'city'} />} />
              <Route path="staffManagement/details/:id" element={<StaffDetails menuType={'staff'} />} />
              <Route path="urbanManagement/details/:id" element={<StaffDetails menuType={'city'} />} />
              <Route path="staffManagement/position/:id" element={<StaffPosition />} />
            </Route>


            <Route path="accessControl">
              <Route path="users" element={<Users />} />
              <Route path="users/details/:userId" element={<UserDetails />} />
              <Route path="roles" element={<Roles />} />
              <Route path="roles/details/:roleId" element={<RoleDetails />} />
            </Route>
            <Route path="workOrderManagement">
              <Route path="workOrder" element={<WorkOrder />} />
              <Route path="workOrder/details/:id" element={<WorkOrderDetails />} />
            </Route>
            <Route path="mobileDataMonitoring">
              <Route path="userAccessRecord" element={<UserAccessRecord />} />
            </Route>
            <Route path="messageCenter">
              <Route path="keySecurityAreaMessage" element={<KeySecurityAreaMessage />} />
            </Route>
            <Route path="logManagement">
              <Route path="consoleLog" element={<ConsoleLog />} />
              <Route path="loginLog" element={<LoginLog />} />
            </Route>

            <Route path="summaryReport">
              <Route path="bikeSummaryReport" element={<BikeSummaryReport />} />
            </Route>
            <Route path="systemSetting" element={<SystemSetting />} />
            <Route path="noPermission" element={<NoPermission />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Routes>
      </ConfigProvider>
    </BrowserRouter>
  );
};

App.propTypes = {};

export default App;
