/**
 * 封装请求函数，失败后重试一次
 * @param {Function} fetchData - 一个返回Promise的函数，执行实际的请求
 * @param {number} retries - 重试次数，默认为1
 * @param {number} delay - 重试前的延迟时间（毫秒），默认为0
 * @returns {Promise}
 */
export function withRetry(fetchData, retries = 1, delay = 0) {
  return new Promise((resolve, reject) => {
    function attempt(currentAttempt) {
      fetchData()
        .then(response => {
          // 请求成功
          resolve(response)
        })
        .catch(error => {
          if (currentAttempt < retries) {
            // 尚未达到重试次数，等待一段时间后再次尝试
            setTimeout(() => {
              attempt(currentAttempt + 1)
            }, delay)
          } else {
            // 达到重试次数，请求失败
            reject(error)
          }
        })
    }

    // 开始第一次尝试
    attempt(0)
  })
}
