import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Form, Select, Input, Space, Cascader, Modal } from 'antd';
import { getAreaTreeFilter } from '../../../../api';
import { areaStreetTreeData } from '../../../../components/forms/AreaTreeFuc';
// import DeviceMakers from '../../../../components/aMap/DeviceMakers';
import LocaScatterLayer from '../../../../components/aMap/LocaScatterLayerPilot';
import DeviceMakers from '../../../../components/aMap/DeviceMakersPilot';
import { storage } from '../../../../utils/storage';
import bikeLegend from '../../../../asset/mapIcon/bike-legend-bg.png';

const ScreenMap = ({
  onFilterFunc,
  mapData,
  bikeType,
  initTimer,
  onMakerClick,
  onFoldCharts,
  fold }) => {
  const roleName = storage.getData('userInfo')?.roleName || null;
  const [searchForm] = Form.useForm();
  const [areaOptions, setAreaOptions] = useState([]);
  const [disabledFilter, setDisabledFilter] = useState(true);
  const [enableFilter, setEnableFilter] = useState(false);
  const [reload, setReload] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [showLegend, setShowLegend] = useState(false);
  const [typeValue, setTypeValue] = useState(1);
  // const [center, setCenter] = useState([104.065861, 30.657401]);
  // const [zoom, setZoom] = useState(13);

  const displayRender = (labels, selectedOptions) => {
    if (selectedOptions[0]) {
      return labels.map((label, i) => {
        const option = selectedOptions[i];
        if (i === labels.length - 1) {
          return <span key={option.value}>{option.areaName}</span>;
        }
        return <span key={option.value}>{label.props.children[0]} / </span>;
      });
    }
    return <span>{labels}</span>;
  };
  const onSearch = (values) => {
    const data = values ?
      {
        ...values.composite,
        searchTab: typeValue,
        areaIds: areaStreetTreeData(values.areaIds, areaOptions, 'search'),
      } :
      {};
    setEnableFilter(true);
    onFilterFunc(data);
  };

  //  地图筛选项变化
  const onFieldsChange = (changedFields, allFields) => {
    let isNull = true;
    allFields.forEach((field) => {
      if (field.value && field.value.length > 0) {
        isNull = false;
      }
    });
    setDisabledFilter(isNull);
    // 搜索内容为空
    if (isNull) {
      // 关闭筛选按钮
      if (enableFilter) {
        initTimer(null);
      }
      setEnableFilter(false);
    }
  };

  const getAreaGroup = () => {
    getAreaTreeFilter({}, 2).then((res) => {
      // 没有id的是区域人员
      // id ===2是 系统管理 不限制
      // id===3 是 区域 不能重名
      // id===4 是 街道
      const type = 2;
      const data = res.data.map((item, index) => {
        return {
          ...item,
          value: item.id,
          disabled: type === 3 && item.managerName,
          areaManagerName: item.managerName || '',
          label: (
            <span>
              {item.areaName}&nbsp;&nbsp;
              <span className="text-light"> {item.managerName || ''}</span>
            </span>
          ),
          children: item.children ?
            item.children.map((sub) => {
              return {
                ...sub,
                label: (
                  <span>
                    {sub.areaName}&nbsp;&nbsp;
                    <span className="text-light"> {sub.managerName || ''}</span>
                  </span>
                ),
                value: sub.id,
                streetManagerName: sub.managerName || '',
                disabled: type === 4 && sub.managerName,
                children: sub.children ?
                  sub.children.map((aff) => {
                    return {
                      ...aff,
                      label: (
                        <span>
                          {aff.areaName}&nbsp;&nbsp;
                          <span className="text-light"> {aff.managerName || ''}</span>
                        </span>
                      ),
                      value: aff.id,
                      streetManagerName: aff.managerName || '',
                      // disabled: type===4&&aff.managerName,
                    };
                  }) :
                  [],
              };
            }) :
            [],
        };
      });
      setAreaOptions(data);
    });
  };

  // 大屏初始化
  const initMap = () => {
    // getUserMapConfig
    // getUserMapConfig();
    // 取消高亮筛选状态
    setEnableFilter(false);
    // 获取区域信息
    getAreaGroup();
    //  关闭搜索框
    setShowFilter(false);
    setDisabledFilter(true);
    //  关闭图例
    setShowLegend(false);
    //  form重置
    setTypeValue(1);
    searchForm.resetFields();
    // 地图筛选按钮置灰
    setEnableFilter(false);
  };
  useEffect(() => {
    initMap();
  }, [bikeType]);
  return (
    <>
      <div className={fold ? 'screen-map-toolbar-full' : 'screen-map-toolbar-pilot'}>
        {/* <div
          onClick={onFoldCharts}
          className="screen-map-toolbar-btn small-icon">
          {fold ? '退出' : '地图'}全屏
        </div> */}
        <div
          className="screen-map-toolbar-btn-pilot reload-icon-pilot"
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            setReload(reload + 1);
          }}
        >
          {/* 恢复默认 */}
        </div>
        <div
          className={'screen-map-toolbar-btn-pilot ' + (enableFilter ? 'filter-icon-on-pilot' : 'filter-icon-pilot')}
          onClick={() => {
            setShowFilter(true);
            setShowLegend(false);
          }}
        >
          {/* 选项筛选 */}
        </div>
        {/* <div
          className="screen-map-toolbar-btn legend-icon"
          onClick={() => {
            setShowLegend(!showLegend);
            setShowFilter(false);
          }}
        >
          图例说明
        </div> */}
      </div>
      <Modal
        className="map-modal-pilot"
        title="筛选"
        width={400}
        mask={false}
        visible={showFilter}
        // maskClosable={false}
        onCancel={() => {
          setShowFilter(false);
        }}
        style={{
          position: 'absolute',
          right: 90,
          top: 85,
          // top: 190,
          // right: -390,
        }}
        wrapClassName="model-map-cockpit"
        getContainer={() => {
          console.log(document.getElementById('mapId'))
          const el = document.getElementById('mapId');
          if (el) {
            return el;
          }
          return document.body;

        }}
        footer={null}>
        <Form
          layout="vertical"
          onFinish={onSearch}
          form={searchForm}
          onFieldsChange={onFieldsChange}
          className="padding-t-m padding-l-m padding-r-m"
        >
          <Form.Item label="信息搜索" className="margin-b0">
            <Input.Group compact>
              <Form.Item name={['composite', 'searchTab']} noStyle initialValue={1}>
                <Select
                  placeholder="请选择"
                  style={{
                    width: '35%',
                  }}
                  // defaultValue={1}
                  onChange={(value) => {
                    setTypeValue(value || null);
                  }}
                >
                  <Select.Option value={1}>设备IMEI</Select.Option>
                  <Select.Option value={2}>设备名称</Select.Option>
                  <Select.Option value={3}>设备位置编号</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name={['composite', 'searchContent']}>
                <Input disabled={!typeValue} placeholder="请输入" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="设备所属区域" name="areaIds" style={{ marginBottom: 8 }}>
            <Cascader
              allowClear={false}
              multiple
              maxTagCount="responsive"
              placement="bottomRight"
              displayRender={displayRender}
              placeholder="请选择（可多选）"
              options={areaOptions}
              style={{
                width: '100%',
              }}
            />
          </Form.Item>

          <div className="text-center padding-t-m padding-b-m">
            <Space>
              <Button
                disabled={disabledFilter}
                type={disabledFilter ? 'default' : 'primary'}
                onClick={() => {
                  // 取消高亮筛选状态
                  setEnableFilter(false);
                  // 按钮置灰
                  setDisabledFilter(true);
                  // 清空搜索内容
                  setTypeValue(1);
                  searchForm.resetFields();
                  if (enableFilter) {
                    initTimer(null);
                  }
                }}
              >
                重置
              </Button>
              <Button disabled={disabledFilter} htmlType="submit" type={disabledFilter ? 'default' : 'primary'}>
                搜索
              </Button>
            </Space>
          </div>

        </Form>
      </Modal>
      <Modal
        className="map-modal"
        title="图例说明"
        width={300}
        mask={false}
        visible={showLegend}
        onCancel={() => {
          setShowLegend(false);
        }}
        style={{
          top: 170,
          right: -430,
        }}
        wrapClassName="model-map"
        footer={null}>
        <img src={bikeLegend} alt="legend" height={155} />
      </Modal>
      {/* <div className={showLegend ? legendStyle : 'hidden'}>
        <div
          className="close"
          onClick={() => {
            setShowLegend(false);
          }}
        />
      </div> */}
      { roleName === '城市管理员'?
        <DeviceMakers
          points={mapData || []}
          reload={reload}
          onMakerClick={onMakerClick}
          bikeType={bikeType}
          fold={fold}
        /> :
        <LocaScatterLayer
          points={mapData || []}
          reload={reload}
          onMakerClick={onMakerClick}
          bikeType={bikeType}
          fold={fold} />}
    </>
  );
};
ScreenMap.propTypes = {
  onFoldCharts: PropTypes.func.isRequired,
  fold: PropTypes.bool.isRequired,
  initTimer: PropTypes.func.isRequired,
  onFilterFunc: PropTypes.func.isRequired,
  mapData: PropTypes.any,
  bikeType: PropTypes.number.isRequired,
  onMakerClick: PropTypes.func.isRequired,
};
export default ScreenMap;
